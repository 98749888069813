/* eslint-disable quotes */
/* eslint-disable import/order */
import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Form } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { AGENTEKEYWORD, AGENTNAME, TOKEN } from '../../_helpers/token';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import { FormControl } from 'react-bootstrap'
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { startAsyncValidation } from 'redux-form';
import TimeRange from 'react-time-range';
import { Portal } from "react-overlays";
import './style.css';
import { MDBCloseIcon } from "mdbreact"


const CalendarContainer = ({ children }) => {
    const el = document.getElementById("calendar-portal");

    return <Portal container={el}>{children}</Portal>;
};
export class PresentersDeposits extends React.Component {
    constructor(props) {
        super(props)
        this._onButtonClick = this._onButtonClick.bind(this);
        this._onButtonClick2 = this._onButtonClick2.bind(this);
        this.getDataUpdate = this.getDataUpdate.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleFromDateUpdate = this.handleFromDateUpdate.bind(this);
        this.handleToDateUpdate = this.handleToDateUpdate.bind(this);
        this.applyCallback = this.applyCallback.bind(this);
        this.changeTime = this.changeTime.bind(this);

        let Sdate = moment(new Date()).format('YYYY-MM-DD');
        let Edate = moment(new Date()).format('YYYY-MM-DD');

        localStorage.setItem("STARTDATE", Sdate + " 00: 00: 00")

        localStorage.setItem("ENDDATE", Edate + " 23: 59: 59")

        this.columns = [
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Date Joined",
                className: "tsc",
                align: "left"
            },

            {
                key: "msisdn",
                TrOnlyClassName: 'tsc',
                text: "Phone",
                className: "tsc",
                align: "left"
            },
            {
                key: "joiningKeyword",
                TrOnlyClassName: 'tsc',
                text: "Keyword",
                className: "tsc",
                align: "left"
            },
            {
                key: "registrationChannel",
                TrOnlyClassName: 'tsc',
                text: "Registration Channel",
                className: "tsc",
                align: "left"
            },
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50, 100],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            filename: "restaurents",
            button: {
                excel: true,
                print: true,
                csv: true
            },
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search table...",
                no_data_text: "No customer was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            },

        }

        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isOpen: false,
            startDate: new Date(),
            endDate: new Date(),
            startTime: new Date(),
            endTime: new Date(),
            isShowError: false,
            start_date: new Date(),
            selectionRange: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
            showComponent: false,
            showModals: false,
            showEdit: false,
            hideComponent: false,
            data: [],
        };

        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");
        this.state = {
            start: start,
            end: end
        }




        this.extraButtons = [
            {
                className: "btn btn-primary buttons-pdf",
                title: "Export TEst",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    // alert("dnhdh")
                    console.log(event);
                },
            },
            {
                className: "btn btn-primary buttons-pdf",
                title: "Export TEst",
                children: [
                    <span>
                        <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    console.log(event);
                },
                onDoubleClick: (event) => {
                    console.log("doubleClick")
                }
            },
        ]

    }


    componentDidMount() {
        // Modal.setAppElement('body');
        this.getData();
    }

    getData = (queryString = "") => {

        // alert(moment.utc("2021-01-29T11:30:00.039Z").format('HH:mm:ss'))
        let Sdate = "2020-01-02 00:00:01";
        let Edate = moment(new Date()).format('YYYY-MM-DD');

        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + "deposits?presenter=" + AGENTEKEYWORD + "&startTime=" + Sdate + " 00:00:00" + "&endTime=" + Edate + " 23:59:59&" + queryString
        // console.log("query", url);

        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    if (this.state.admins.length > 0 || this.state.admins.length === 0) {

                        // alert(JSON.stringify(branchResponse ))
                        for (let i = 0; i < this.state.admins.length; i++) {
                            this.setState({
                                total: branchResponse.data.total,
                                total_amount: branchResponse.data.total_amount
                            })


                            let mail;
                            let mbalance;
                            let phone;
                            let index = { idx: i + 1 }

                            let mDeposit;
                            // mDeposit = { mDeposit: this.state.admins[i].deposit_amount.toString() };
                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };
                            }

                            let date = { dates: moment.utc(this.state.admins[i].timeCreated).format('YYYY-MM-DD HH:mm:ss') };

                            data.push(Object.assign(date, phone, index, this.state.admins[i]));

                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);

                        }
                    }
                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }


    applyCallback() {
        // alert(JSON.stringify())
        let sTime = moment(this.state.startTime).format('HH:mm:ss');
        let eTime = moment(this.state.endTime).format('HH:mm:ss');

        let Sdate = moment(this.state.start_date).format('YYYY-MM-DD');
        this.closeModal();

        let startDate = Sdate + " " + sTime
        let endDate = Sdate + " " + eTime

        localStorage.setItem("STARTDATE", startDate)

        localStorage.setItem("ENDDATE", endDate)

        this.getData2(startDate, endDate);

    }

    getData2 = (startDate, endDate, queryString = "") => {

        let Sdate = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
        let Edate = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };

        let url = baseURL + "deposits?presenter=" + AGENTEKEYWORD + "&startTime=" + Sdate + "&endTime=" + Edate + "&" + queryString
        // console.log("query", url);
        axios.all([
            axios.get(url, axiosConfig),
        ]).then(axios.spread((branchResponse) => {

            console.log(branchResponse.data)
            this.setState({
                admins: branchResponse.data.data,
                total: branchResponse.data.total,
                isLoading: false,
            },
                function () {
                    var data = [];
                    if (this.state.admins.length > 0) {

                        // alert(JSON.stringify(branchResponse ))
                        for (let i = 0; i < this.state.admins.length; i++) {
                            this.setState({
                                total: branchResponse.data.total,
                                total_amount: branchResponse.data.total_amount
                            })
                            let phone;
                            let index = { idx: i + 1 }

                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };
                            }

                            let date = { dates: moment(this.state.admins[i].timeCreated).format('YYYY-MM-DD HH:mm:ss') };
                            data.push(Object.assign(date, phone, index, this.state.admins[i]));

                            this.setState({
                                data: data
                            })
                        }
                    }
                    else {
                        this.setState({
                            data: data
                        })
                    }
                })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }



    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column")
                    + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);

        this.getData2(localStorage.getItem("STARTDATE"), localStorage.getItem("ENDDATE"), queryString)
    }



    getDataUpdate() {
        let queryString = ("filter_value=&page_number=0&page_size=10&sort_order=false")
        this.getData()
    }

    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showModal: false,
        });
    }

    _onButtonClick2() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            showModal: false,
            showModals: false
        });
    }

    onEdit(record) {
        this.setState({
            showComponent: false,
            hideComponent: true,
            showEdit: true,
        }, function () {
            // localStorage.setItem("question_id", this.state.survey_id)
        });

    }

    onAnswers(_record) {
        localStorage.setItem("question_id", this.state.survey_id)

        this.setState({
            showComponent: false,
            hideComponent: true,
            showModal: true,
        }, function () {
        });

    }

    handleFromDateUpdate(date) {
        this.setState({
            startDate: date.date
        })
    }
    handleToDateUpdate(date) {
        this.setState({
            endDate: date.date
        })
    }


    handleSelect(ranges) {
        this.setState({
            selectionRange: {
                startDate: ranges.selection.startDate,
                endDate: ranges.selection.endDate,
                key: 'selection',
            }
        })
        //    alert(window.location.href)
        //     console.log("DATETIMR", JSON.stringify(this.state.selectionRange));
    }
    toggleModal = e => {
        this.setState({
            isOpen: true,
        });
    };

    closeModal = e => {
        this.setState({
            isOpen: false,
        });
    };

    closeModal2 = e => {
        this.setState({
            isOpen: false,
        });
    };




    changeTime(evt) {
        // Fetch our current start and end time values
        let sValue = this.props.startMoment;
        let eValue = this.props.endMoment;
        // Manipulate time based on value selected
        // alert(this.state.startTime)
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;
        const { showModal } = this.state;

        const { showModals } = this.state;

        let ranges = {
            "Today Only": [moment(this.state.start), moment(this.state.end)],
            "Yesterday Only": [
                moment(this.state.start).subtract(1, "days"),
                moment(this.state.end).subtract(1, "days")
            ],
            "3 Days": [moment(this.state.start).subtract(3, "days"), moment(this.state.end)],
            "5 Days": [moment(this.state.start).subtract(5, "days"), moment(this.state.end)],
            "1 Week": [moment(this.state.start).subtract(7, "days"), moment(this.state.end)],
            "2 Weeks": [moment(this.state.start).subtract(14, "days"), moment(this.state.end)],
            "1 Month": [moment(this.state.start).subtract(1, "months"), moment(this.state.end)],
            "1 Year": [moment(this.state.start).subtract(1, "years"), moment(this.state.end)]
        };
        let local = {
            "format": "DD-MM-YYYY HH:mm",
            "sundayFirst": false
        }
        return (
            <div >
                {!hideComponent && (
                    < >
                        < Col md={12} lg={12} >
                            < Card >

                                <CardBody >


                                    <Modal
                                        isOpen={this.state.isOpen}
                                        onRequestClose={e => {
                                            this.closeModal(e);
                                        }}
                                        contentLabel="My dialog"
                                        className="modalpresenter"
                                        onAfterOpen={() => {
                                            document.body.style.overflow = 'hidden';
                                        }}
                                        onAfterClose={() => {
                                            document.body.removeAttribute('style');
                                        }}
                                        overlayClassName="myoverlay"
                                        closeTimeoutMS={500}
                                    >
                                        <MDBCloseIcon onClick={this.closeModal} />
                                        <h4><b>Filtering</b></h4><br />

                                        <div className="col-md-12 text-center">


                                            <DatePicker
                                                selected={this.state.start_date}

                                                label="DatePicker Label"
                                                dateFormat="yyyy-MM-dd"
                                                className="form-control"
                                                placeholderText="Filter by Date"
                                                value={this.start_date}
                                                menuPortalTarget={document.body}
                                                popperContainer={CalendarContainer}
                                                onChange={value => this.setState({ start_date: value })} /><br /><br />
                                            <TimeRange
                                                startMoment={moment(this.state.startTime)}
                                                endMoment={moment(this.state.endTime)}
                                                minuteIncrement="1"
                                                startLabel="Start time"
                                                endLabel="End time"
                                                use24Hours={true}
                                                onStartTimeChange={value => this.setState({ startTime: value.startTime })}
                                                onEndTimeChange={value => this.setState({ endTime: value.endTime })}
                                            />
                                        </div>
                                        <br />

                                        <div className="col-md-6 offset-2">
                                            <br />
                                            <button
                                                type="submit"
                                                onClick={this.applyCallback}
                                                className="pull-right btn btn-primary btn-sm">
                                                Submit
                                            </button>
                                        </div>

                                    </Modal>


                                    <div className="panel-body" >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4> <b>Customers</b></h4><br />
                                            </div>

                                            <div className="col-md-6">
                                                <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}
                                                    onClick={this.getDataUpdate}>
                                                    <RefreshIcon />
                                                </button>
                                            </div>
                                        </div>
                                        {this.state.showError ? <div style={{ color: 'red' }}>
                                            {this.state.statusMessage}
                                        </div> : null}
                                        {this.state.isShowError ? (
                                            <div
                                                color="success"
                                                style={{ fontSize: "13px", color: "green" }}>
                                                {this.state.statusMessage}
                                            </div>

                                        ) : null}
                                        <br /><br />

                                        <div className="row" >
                                            <div className="col-4" >
                                                <h5>Total Customers: <b> {this.state.total} </b></h5 >
                                            </div>
                                            < div className="col-4" >
                                                {/* <h5>Total Amount: <b>{this.state.total_amount} < /b></h5 > <br /> */}
                                            </div>
                                            {/* < div className="col-md-4" >
                                                <button className="btn btn-primary float-right" title="Filter"
                                                    onClick={this.toggleModal} >
                                                    Click to Filter By Session Time/Date
                                                </button> 
                                            </div> */}
                                        </div>
                                        < br />
                                        <br />
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            dynamic={true}
                                            id="tsc"
                                            extraButtons={this.extraButtons}
                                            loading={this.state.isLoading}
                                            total_record={this.state.total}
                                            onChange={this.tableChangeHandler} />
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                    </>
                )
                }
            </div>
        )
    }
}