export const TOKEN = localStorage.getItem('token');;

export const ROLE = localStorage.getItem('AGENTKEYWORD');



export const AGENTNAME = localStorage.getItem('AGENTNAME');
export const AGENTID = localStorage.getItem('AGENTID');
export const AGENTPHONE = localStorage.getItem('AGENTPHONE');
export const AGENTEMAIL = localStorage.getItem('AGENTEMAIL');

export const AGENTEKEYWORD = localStorage.getItem('AGENTKEYWORD');


export const SHORTCODE = localStorage.getItem('shortc');
export const NAME = localStorage.getItem('AGENTNAME');
export const STATE = localStorage.getItem('state');